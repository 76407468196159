<template>
  <app-module-view>
    <template slot="body">
      <app-feature-filter></app-feature-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="features"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../../components/ModuleView'
import { mapState } from 'vuex'
import Preloader from '../../preloader/Preloader'
import FeatureFilter from './FeatureFilter'
import DataTable from '../../../components/shared/DataTable'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'FeatureListView',
  data () {
    return {
      dataLoaded: false,
      dataTableConfig: {
        fields: {
          id: this.$t('eshopFeature.list.id'),
          name: this.$t('eshopFeature.list.name'),
          description: this.$t('eshopFeature.list.description'),
          enabled: this.$t('eshopFeature.list.enabled')
        },
        actions: {
          edit: 'eshopFeature_edit'
        },
        render: {
          enabled: function (data) {
            if (data) {
              return '<i class="fas fa-check-circle"></i>'
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isEditButtonVisible () {
      const permission = PermissionService.REQUIRED_PERMISSIONS.SYSTEM_ESHOP_FEATURE_PERMISSIONS.editButton
      return this.$store.getters['user/hasPermission'](permission)
    },
    features () {
      return this.$store.getters['eshopFeature/list']
    },
    totalCount () {
      return this.$store.getters['eshopFeature/totalCount']
    },
    page () {
      return this.$store.getters['eshopFeature/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFeatureFilter: FeatureFilter,
    appDataTable: DataTable
  },
  methods: {
    getFeatures () {
      this.$store.dispatch('eshopFeature/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    setPageAndGetRecords (page) {
      this.$store.commit('eshopFeature/setPage', page)
      this.getFeatures()
    }
  },
  mounted () {
    this.getFeatures()
  }
}
</script>

<style lang="scss" scoped>

.table-responsive {
  th, td {
    font-size: 14px
  }
}

</style>
